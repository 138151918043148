<template>
	<wrapper>
		<Html lang="nl" />
		<Head>
			<Title>De Weie | Bed en Breakfast in het hart van Burgh-Haamstede</Title>
			<Meta
				name="description"
				content="Welkom in ons gezellige vakantiehuisje in het hart van Burgh-Haamstede! Geniet van de levendige sfeer op de gezellige terrasjes en de vele restaurants om de hoek. Vanuit het huisje sta je binnen een minuut in het centrum, maar je kunt ook op korte loopafstand kiezen voor het bos en de Zeepeduinen."
			/>
			<Meta name="robots" content="INDEX,FOLLOW" />
		</Head>

		<img class="logo" src="~/assets/images/logo.png" alt="De Weie" />
		<slot />
	</wrapper>
</template>

<style lang="scss">
.logo {
	display: block;
	margin: 0 auto 20px;
	width: 300px;
}

h2.subtitle {
	margin: 0;
}

@media (max-width: 1080px) {
	.column {
		padding: 0;
	}

	.column p {
		margin: 15px 0 30px;
	}
}
</style>
