<template>
	<div class="wrapper">
		<slot />
	</div>
</template>

<style scoped>
.wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 75px 50px 80px;
	display: flex;
	flex-direction: column;
	text-align: center;
}

@media (max-width: 1080px) {
	.wrapper {
		flex-direction: column;
		text-align: center;
	}
}

@media (max-width: 500px) {
	.wrapper {
		padding: 50px;
	}
}
</style>
